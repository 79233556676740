import React, {useEffect} from 'react'
import { graphql, Link } from "gatsby"
import Header from '../components/header'
import Footer from '../components/footer'
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink/index"

const ProjectTypes = (props) => {
    useEffect(() => {

    })

    return (
      <main style={{backgroundColor: '#ECECEC'}}>
        <Header projects={true} background={'#ECECEC'} {...props} />

        <section className="main-content">
          <div className="container-fluid pos-rel">
            <h1 className={"main"}>Features</h1>

            {props.data.allFeatures.edges.map((type, key) => {
              if(!type.node.image.gallery)
                return null

              return (
                <AniLink fade key={key} to={"/projects/" + type.node.slug} className="project">
                  <div className="row">
                    <div className="col-md-9">
                      <picture>
                        <img src={type.node.image.gallery} />
                      </picture>
                    </div>

                    <div className="col-md-3">
                      <h3>{type.node.title}</h3>
                    </div>
                  </div>
                </AniLink>
              )
            })}

            <h1 className={"main"}>Listings</h1>

            <div className="row">
              {props.data.allListings.edges.map((type, key) => {
                if (!type.node.image.sidebar)
                  return null

                return (
                  <div key={key} className="col-6">
                    <AniLink fade direction={"left"} className="article project"
                             to={"/projects/" + type.node.slug}>
                      <div className="row">
                        <div className="col-md-6">
                          <picture>
                            <img src={type.node.image.sidebar}/>
                          </picture>
                        </div>

                        <div className="col-md-6">
                          <h3>{type.node.title}</h3>
                        </div>
                      </div>
                    </AniLink>
                  </div>
                )
              })}
            </div>
          </div>
        </section>

        <Footer {...props} />
      </main>
    )
}

export const query = graphql`
  {
    allFeatures {
      edges {
        node {
          title
          slug
          image {
            gallery
          }
        }
      }
    }
    allListings {
      edges {
        node {
          title
          slug
          image {
            sidebar
          }
        }
      }
    }
  }
`

export default ProjectTypes